// Spacing utilities
.no-margin {
  margin: 0 !important;
}

// Flex utilities
.mzic-flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: flex-end;
}

.justify-around {
  justify-content: space-around;
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}
