.tracks-release-input-section-title {
  @extend .text-heading-md-semibold;
  padding-top: 56px;
  margin-top: 56px;
  margin-bottom: 32px;
  border-top: 1px solid var(--neutral-500);
}

.tracks-release-section-text {
  @extend .text-body-xxsm;
  color: var(--neutral-200);
}

.tracks-release-fields-container {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .sub-row {
    margin-top: -24px;
  }
}
