/* Regular Font */
@font-face {
  font-family: 'PP Mori';
  src: url('./PP-Mori-Regular-v1.0/WEB/PPMori-Regular.woff2') format('woff2'),
       url('./PP-Mori-Regular-v1.0/WEB/PPMori-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* semibold Font */

/* Medium Font */
@font-face {
  font-family: 'PP Mori';
  src: url('./PP-Mori-Medium/PPMori-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}



/* Bold Font */
@font-face {
  font-family: 'PP Mori';
  src: url('./PP-Mori-Bold-v1.0//WEB/PPMori-Bold.woff2') format('woff2'),
    url('./PP-Mori-Bold-v1.0//WEB/PPMori-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

/* semibold Font */
@font-face {
  font-family: 'PP Mori'; /* Nome da fonte */
  src: url('./PP-Mori-SemiBold/PPMori-SemiBold.otf') format('opentype'); /* URL do arquivo de fonte OTF */
  font-weight: 600; /* Peso da fonte (normal, bold, etc.) */
  font-style: normal; /* Estilo da fonte (normal, italic, etc.) */
}







