.rule-resume-card-component {
  display: inline-flex;
  width: 100%;
  flex-direction: column;
  gap: 16px;

  .rule-card {
    display: inline-flex;
    flex-direction: column;
    border: 1px solid var(--neutral-500);
    border-radius: 24px;
    padding: 12px 24px;
    background-color: transparent;
    width: 100%;
    color: var(--neutral-300);

    .card-title {
      @extend .text-body-md-bold;
      margin-bottom: 8px;
    }

    .content {
      @extend .text-body-xxsm;
      display: inline-flex;
      width: 100%;
      justify-content: space-between;
      color: var(--neutral-300);

      .strong {
        @extend .text-body-xxsm-semibold;
        color: var(--neutral-100);
      }
    }
  }
}
