.mzic-bank-account {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  padding: 16px;
  border: 1px solid var(--neutral-500-50);

  &.is-checked-true {
    border-color: var(--purple-500);
  }

  > div {
    display: inline-flex;
    gap: 8px;
    align-items: center;

    &:last-child {
      gap: 16px;
    }
  }

  .icon {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 52px;
    height: 52px;
    background-color: var(--neutral-500-50);
    border-radius: 50%;

    .flag {
      position: absolute;
      width: 24px;
      height: 24px;
      bottom: 0px;
      right: -8px;
    }
  }

  .text {
    display: inline-flex;
    flex-direction: column;
    gap: 0;
    align-items: flex-start;
    padding-left: 10px;

    .tag {
      @extend .text-body-sm-bold;
      font-size: 10px;
      line-height: 14px;
      color: var(--neutral-700);
      background-color: var(--purple-500);
      border-radius: 4px;
      padding: 3px 4px 1px;
      margin-bottom: 4px;
    }

    .title {
      @extend .text-body-md-semibold;
      color: var(--neutral-100);
    }

    p {
      @extend .text-body-xsm;
      color: var(--neutral-300);
    }
  }

  input[type='checkbox'] {
    margin-right: 16px;
  }

  .remove-btn:hover {
    background-color: var(--red-500);
  }
}

.mzic-bank-account.dark {
  border-color: transparent;
  background-color: var(--neutral-600-50);
}
