.mz-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 18px 24px 14px;
  border-radius: 100px;
  border: 0;
  background-color: var(--purple-500);
  font-size: 16px;
  line-height: 24px;
  font-weight: var(--font-semi-bold);
  transition: background-color 0.3s;
  gap: 8px;
  border: 1px solid transparent;
  color: var(--neutral-600);
  cursor: pointer;

  &:not(.stroke) > mzic-svg > svg > path {
    fill: var(--neutral-600);
  }

  &.stroke > mzic-svg > svg > path {
    stroke: var(--neutral-600);
  }

  &.full {
    width: 100%;
  }

  &:hover {
    background-color: var(--purple-400);
  }

  &.error {
    border-color: var(--red-500);
  }

  mzic-spinner {
    display: inline-flex;
    align-items: center;
    margin-top: -2px;
    .spinner {
      border-color: var(--neutral-400);
      border-bottom-color: var(--neutral-500);
    }
  }

  &.only-icon mzic-spinner {
    margin-top: 0;
  }

  mzic-svg {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    max-height: 18px;
    margin-top: -2px;

    > svg {
      width: 18px;
      max-height: 18px;
      transition: 0.3s;
      > path {
        transition: 0.3s;
      }
    }
  }

  &.only-icon {
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 56px;
    padding: 0;

    > mzic-svg {
      width: 24px;
      max-height: 24px;
      margin-top: 0;
      > svg {
        width: 24px;
        max-height: 24px;
      }
    }
  }

  &.medium {
    padding: 12px 24px 10px;
    font-size: 14px;
    line-height: 20px;

    > mzic-svg {
      width: 20px;
      max-height: 20px;

      > svg {
        width: 20px;
        max-height: 20px;
      }
    }

    &.only-icon {
      width: 44px;
      height: 44px;
      padding: 0;

      > mzic-svg {
        width: 20px;
        max-height: 20px;
        > svg {
          width: 20px;
          max-height: 20px;
        }
      }
    }
  }

  &.small {
    padding: 10px 16px 7px;
    font-size: 14px;
    line-height: 20px;

    > mzic-svg {
      width: 16px;
      max-height: 16px;
      > svg {
        width: 16px;
        max-height: 16px;
      }
    }

    &.only-icon {
      width: 36px;
      height: 36px;
      padding: 0;

      > mzic-svg {
        width: 14px;
        max-height: 14px;

        > svg {
          width: 14px;
          max-height: 14px;
        }
      }
    }
  }

  &.mini {
    padding: 6px 18px 3px;
    font-size: 12px;
    line-height: 16px;

    > mzic-svg {
      width: 12px;
      max-height: 12px;

      > svg {
        width: 12px;
        max-height: 12px;
      }
    }

    &.only-icon {
      width: 24px;
      height: 24px;
      padding: 0;

      > mzic-svg {
        width: 12px;
        max-height: 12px;
        > svg {
          width: 12px;
          max-height: 12px;
        }
      }
    }
  }
}

.mz-button.white {
  mzic-spinner {
    .spinner {
      border-color: var(--neutral-100);
      border-bottom-color: var(--neutral-200);
    }
  }
}

.mz-button.white:not(.stroke) > mzic-svg > svg > path {
  fill: var(--neutral-100);
}

.mz-button:disabled,
.mz-button.white:disabled {
  background-color: var(--neutral-300) !important;
  color: var(--neutral-400) !important;
  border-color: transparent !important;
  cursor: not-allowed;

  &.stroke > mzic-svg > svg > path {
    stroke: var(--neutral-400);
  }

  &:not(.stroke) > mzic-svg > svg > path {
    fill: var(--neutral-400);
  }
}

button.secondary,
input[type='button'].secondary {
  background-color: var(--neutral-500-50);
  color: var(--neutral-100);

  &.stroke > mzic-svg > svg > path {
    stroke: var(--neutral-100);
  }

  &:not(.stroke) > mzic-svg > svg > path {
    fill: var(--neutral-100);
  }

  &:hover {
    background-color: var(--neutral-600);
  }
}

button.tertiary,
input[type='button'].tertiary {
  background-color: var(--neutral-500-50);
  color: var(--purple-500);
  border: 1px solid var(--neutral-500-50);

  &.stroke > mzic-svg > svg > path {
    stroke: var(--purple-500);
  }

  &:not(.stroke) > mzic-svg > svg > path {
    fill: var(--purple-500);
  }

  &:hover {
    background-color: var(--neutral-500);
  }
}

button.outline,
input[type='button'].outline {
  background-color: transparent;
  color: var(--purple-500);
  border-color: var(--purple-500);

  &.stroke > mzic-svg > svg > path {
    stroke: var(--purple-500);
  }

  &:not(.stroke) > mzic-svg > svg > path {
    fill: var(--purple-500);
  }

  &:hover {
    background-color: var(--neutral-500-50);
  }
}

button.outline.white,
input[type='button'].outline.white {
  background-color: transparent;
  color: var(--neutral-100);
  border-color: var(--neutral-100);

  &.stroke > mzic-svg > svg > path {
    stroke: var(--neutral-100);
  }

  &:not(.stroke) > mzic-svg > svg > path {
    fill: var(--neutral-100);
  }

  &:hover {
    background-color: var(--neutral-500-50);
  }
}

button.outline.weak,
input[type='button'].outline.weak {
  background-color: transparent;
  color: var(--neutral-100);
  border-color: var(--neutral-500);

  &.stroke > mzic-svg > svg > path {
    stroke: var(--neutral-100);
  }

  &:not(.stroke) > mzic-svg > svg > path {
    fill: var(--neutral-100);
  }

  &:hover {
    background-color: var(--neutral-500-50);
  }
}

button.ghost,
input[type='button'].ghost {
  background-color: transparent;
  color: var(--purple-500);

  &.stroke > mzic-svg > svg > path {
    stroke: var(--purple-500);
  }

  &:not(.stroke) > mzic-svg > svg > path {
    fill: var(--purple-500);
  }

  &:hover {
    background-color: var(--neutral-600);
  }

  &:disabled {
    background-color: transparent !important;
    color: var(--neutral-400);
  }
}

button.link,
input[type='button'].link {
  padding: 0;
  background-color: transparent;
  color: var(--purple-500);

  mzic-spinner .spinner {
    border-color: var(--purple-500);
    border-bottom-color: var(--purple-600);
  }

  &.stroke > mzic-svg > svg > path {
    stroke: var(--purple-500);
  }

  &:not(.stroke) > mzic-svg > svg > path {
    fill: var(--purple-500);
  }

  &:hover {
    background-color: transparent;
    color: var(--purple-400);

    &.stroke > mzic-svg > svg > path {
      stroke: var(--purple-400);
    }

    &:not(.stroke) > mzic-svg > svg > path {
      fill: var(--purple-400);
    }
  }

  &:disabled {
    background-color: transparent !important;
    color: var(--neutral-400);
  }
}

button.danger,
input[type='button'].danger {
  background-color: var(--red-500);

  &:hover {
    background-color: var(--red-300);
  }
}

button.success,
input[type='button'].success {
  background-color: var(--green-500);

  &:hover {
    background-color: var(--green-300);
  }
}

.mz-button.stroke-neutral-700 > mzic-svg > svg > path {
  stroke: var(--neutral-700) !important;
}
.mz-button.stroke-white-hover:hover > mzic-svg > svg > path {
  stroke: #fff !important;
}
.mz-button.fill-white-hover:hover > mzic-svg > svg > path {
  fill: #fff !important;
}

.mz-button-tag {
  -webkit-appearance: none;
  display: inline-flex;
  gap: 4px;
  padding: 12px 16px;
  border-radius: 100px;
  background-color: var(--neutral-500-50);
  border: 0;
  transition: background-color 0.3s;

  &:hover {
    background-color: var(--neutral-500);
  }

  &.active {
    .number {
      color: var(--purple-500);
    }
  }

  .label,
  .number {
    @extend .text-body-xxsm-bold;
    font-weight: var(--font-medium);
    margin-top: 2px;
  }

  .label {
    color: var(--neutral-100);
  }

  .number {
    color: var(--neutral-400);
  }
}
