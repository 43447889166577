mzic-svg {
  position: relative;
  display: inline-flex;
  width: 24px;
  max-height: 24px;

  > svg {
    width: 24px;
    max-height: 24px;
  }
}

.mz-button.stroke mzic-svg > svg > path {
  fill: transparent !important;
}

mzic-svg:not(.stroke) > svg > path {
  fill: var(--neutral-100);
}
mzic-svg.stroke > svg > path {
  stroke: var(--neutral-100);
}

mzic-svg {
  @for $i from 1 through 96 {
    &.w#{$i} {
      width: #{$i}px;
      max-height: #{$i}px;
      > svg {
        width: #{$i}px;
        max-height: #{$i}px;
      }
    }
  }
}
