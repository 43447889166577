.split-details-resume-component {
  position: relative;
  display: inline-flex;
  width: 100%;
  gap: 24px;
  justify-content: space-between;
  align-items: center;

  .image-container {
    position: relative;
    width: 200px;

    .image {
      position: relative;
      display: inline-flex;
      width: 173px;
      height: 173px;
      border-radius: 24px;
      overflow: hidden;
      background-color: var(--neutral-500);
      top: 0;
      left: 0;
      z-index: 3;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    .image + .image {
      position: absolute;
      background-color: var(--neutral-500-50);
      top: 11px;
      left: 12px;
      z-index: 2;
    }
    .image + .image + .image {
      position: absolute;
      background-color: var(--neutral-600);
      top: 22px;
      left: 24px;
      z-index: 1;
    }
  }

  .text-container {
    display: inline-flex;
    flex-direction: column;
    width: calc(100% - 200px);
    align-items: flex-start;
    gap: 8px;

    .tag {
      @extend .text-body-sm-bold;
      display: inline-flex;
      padding: 2px 10px;
      border-radius: 4px;
      background-color: var(--neutral-100);
      color: var(--neutral-700);
      align-items: center;
      gap: 4px;
      cursor: pointer;
      margin-bottom: 8px;

      span {
        margin-bottom: -2px;
      }
    }

    .title {
      display: inline-flex;
      gap: 7px;
      align-items: center;

      .icon {
        display: inline-flex;
        width: 32px;
        height: 32px;
        min-width: 32px;
        min-height: 32px;
        background-color: var(--neutral-500);
        border-radius: 50%;
        align-items: center;
        justify-content: center;
      }

      .text {
        @extend .text-heading-sm-semibold;
      }
    }

    .resume {
      display: inline-flex;
      flex-direction: column;
      margin-top: 8px;
      gap: 4px;

      span {
        @extend .text-body-xsm;
        color: var(--neutral-300);
      }

      strong {
        @extend .text-body-xsm-bold;
        color: var(--purple-500);
      }
    }

    .dates {
      display: inline-flex;
      gap: 20px;
      align-items: center;

      > div {
        display: inline-flex;
        flex-direction: column;
        gap: 4px;

        span {
          @extend .text-body-xsm;
          color: var(--neutral-300);
        }
        strong {
          @extend .text-body-xsm-bold;
          color: var(--neutral-100);
        }
      }
    }
  }
}
