.mzic-select {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  > label {
    @extend .text-body-md-semibold;

    .required {
      color: var(--red-500);
      font-size: 15px;
    }
  }

  .selected {
    @extend .mzic-input-style;
  }
}

$max-size-overflow: 90%;

.select-option-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.select-option {
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: flex-start;
  width: $max-size-overflow;

  &__image {
    display: flex;
    flex-shrink: 0;
    width: 34px;
    height: 34px;

    img {
      width: 100%;
      border-radius: 100%;
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    min-width: 0;

    > header {
      display: flex;
      align-items: center;
      gap: 3px;
    }

    &-title {
      @extend .text-body-xxsm;
      color: var(--neutral-100);
    }

    &-version {
      @extend .text-body-xsm-bold;
      color: var(--neutral-300);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-description {
      @extend .text-body-xsm;
      color: var(--neutral-300);
      width: $max-size-overflow;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
