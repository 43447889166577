@import './variables';

@import './components/general/labels';
@import './components/general/colors';
@import './components/general/scrollbar';
@import './components/general/buttons';
@import './components/general/inputs';
@import './components/general/tables';
@import './components/general/icons';
@import './components/general/alerts';
@import './components/general/selects';
@import './components/general/tabs';
@import './components/general/popover';
@import './components/general/popover-modal';
@import './components/general/popover-dark-small';
@import './components/general/popover-white-small';
@import './components/general/popover-toast';
@import './components/general/popover-tooltip';
@import './components/general/bank-account';
@import './components/general/checkbox';
@import './components/general/common';

@import './components/interface/audio-player';
@import './components/interface/tips-on-hover';
@import './components/interface/full-page-icon';
@import './components/interface/rule-resume-card-component';
@import './components/interface/sort-number';
@import './components/interface/split-details-resume';
@import './components/interface/split-details-payees-list-component';
@import './components/interface/track-status-tag';
@import './components/interface/toggle-content-link';
@import './components/interface/floating-purple-footer';
@import './components/interface/full-page-form-components';

body {
  background-color: var(--id-main-black);
  margin: 0;
  padding: 0;
  font-family: 'PP Mori', Arial, sans-serif;
  height: 100%;

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @media (prefers-color-scheme: dark) {
    :root {
      color-scheme: only light;
    }
  }

  a {
    text-decoration: none;
    cursor: pointer;
  }

  input {
    caret-color: var(--id-main-purple);
  }
}

.hide {
  display: none;
}

.no-margin {
  margin: 0;
}

.clickable {
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
}

#globalServiceLoader {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999999;

  .loader,
  .icon {
    display: none;
  }

  &.visible {
    display: block;
    .loader {
      display: block;
    }
  }

  &.success {
    display: block;
    .icon {
      display: flex;
      width: 80px;
      height: 80px;
      border: 3px solid var(--green-500);
      border-radius: 50%;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      align-items: center;
      justify-content: center;
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 3px solid var(--neutral-400);
    border-top: 3px solid var(--purple-500);
    border-radius: 50%;
    width: 80px;
    height: 80px;
    margin-top: -40px;
    margin-left: -40px;
    background: transparent;
    animation: spin 0.9s linear infinite;
  }
}

.mzic-tooltip-container {
  position: relative;
  width: fit-content;
  height: fit-content;
}

////////////////////////////////////////////////////////////////////////////////
/// ////////////////////////////////////////////////////////////////////////////
///////////// @@@@ todo: remover, nao precisa mais do estilo abaixo pois o estilo já está dentro do novo componente do side menu flutuante  <mzic-side-floating-menu />
.side-floating-menu {
  position: fixed;
  top: 292px;
  left: 38px;

  ul {
    display: flex;
    flex-direction: column;
    gap: 27px;
    margin: 0;
    padding: 0;

    li {
      position: relative;
      @extend .text-body-xxsm;
      list-style: none;
      padding-left: 28px;
      color: var(--neutral-400);

      &::after {
        transform: translate(0, -50%);
        position: absolute;
        left: 0;
        top: 50%;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background-color: var(--neutral-400);
        content: '';
      }
    }

    li.active {
      color: var(--purple-500);
      &::after {
        background-color: var(--purple-500);
      }
    }
  }
}
////////////////////////////////////////////////////////////////////////////////
/// ////////   REMOVER O CODIGO ACIMA E USAR O COMPONENTE CORRETO //////////////////
/// ////////////////////////////////////////////////////////////////////////////
