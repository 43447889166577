.mzic-popover.style-dark-small {
  background-color: var(--neutral-500);
  border-radius: 24px;
  padding: 9px 14px;
  gap: 6px;

  > div {
    color: #fff;
    font-size: 14px;
    line-height: 160%;
    font-weight: var(--font-regular);
    padding: 0;

    a,
    button,
    .clickable {
      cursor: pointer;
      display: inline-flex;
      width: 100%;
      border-radius: 100px;
      padding: 1px 11px;
    }

    a:hover,
    button:hover,
    .clickable:hover {
      background: var(--neutral-700);
    }
  }
}
