.mzic-audio-player-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .buttons-container {
    display: inline-flex;
    width: 42px;
    align-items: center;

    mzic-svg {
      display: inline-flex;
      max-width: 42px;
      width: 42px;
      max-height: 42px;
      cursor: pointer;

      > svg {
        width: 100%;
        max-height: 100%;
      }
    }
  }

  .wave-container {
    display: inline-flex;
    width: calc(100% - 42px);
    position: relative;
  }
}
