.tracks-release-footer {
  display: inline-flex;
  background-color: var(--purple-500);
  padding: 10px 12px 66px 12px;
  margin-top: 64px;
  justify-content: center;

  > div {
    gap: 32px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
}

.tracks-release-save-progress {
  display: inline-flex;
  position: fixed;
  background-color: var(--purple-500);
  transform: translate(-50%, 0);
  gap: 32px;
  bottom: 40px;
  padding: 10px 16px;
  border-radius: 100px;
  justify-content: center;
  left: 50%;

  &.hide {
    display: none;
  }
}

.tracks-release-button.mz-button {
  color: var(--neutral-700);

  &:hover {
    background-color: var(--neutral-700);
    color: #fff;
    fill: #fff;
  }

  &.save.mz-button {
    border-color: var(--neutral-700);
  }
}
