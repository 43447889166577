$navigation-width: 236px;
$navigation-width-collapsed: 68px;
$navigation-inner-padding: 12px;
$dashboard-inner-padding: 38px;
$table-fixed-footer-height: 72px;

////////////////////////////////
/// Neutral
/// to convert https://rgbacolorpicker.com/rgba-to-hex
$neutral-100: #ededed;
$neutral-200: #c5c5c5;
$neutral-200-50: #c5c5c580;
$neutral-300: #878787;
$neutral-400: #545454;
$neutral-500: #27262d;
$neutral-500-50: #27262d80;
$neutral-600: #1a1a1d;
$neutral-600-50: #1a1a1d80;
$neutral-700: #111010;
$neutral-700-70: #111010b3;

////////////////////////////////
/// Auxiliary
$auxiliary-200-24: #999aba3d;

////////////////////////////////
/// Black / White
$black: #000;
$white: #fff;

////////////////////////////////
/// Purple
$purple-700: #552ab7;
$purple-600: #743edb;
$purple-500: #9655ff;
$purple-400: #b57fff;
$purple-300: #c799ff;
$purple-200: #dcbbff;
$purple-100: #efddff;

$red-700: #91102e;
$red-600: #ad172c;
$red-500: #ca2029;
$red-400: #ea3933;
$red-300: #ff7272;
$red-200: #ff9999;
$red-100: #ffd2d2;

$yellow-700: #b7801f;
$yellow-600: #dba12e;
$yellow-500: #ffc53f;
$yellow-400: #ffd76f;
$yellow-300: #ffe38b;
$yellow-200: #ffeeb2;
$yellow-100: #fff8d8;

$green-700: #049a86;
$green-600: #06b88f;
$green-500: #09d793;
$green-400: #41e79f;
$green-300: #67f3a9;
$green-200: #9afbbe;
$green-100: #ccfdd9;

$blue-700: #2063b3;
$blue-600: #2e84d6;
$blue-500: #40a9f9;
$blue-400: #6fc6fb;
$blue-300: #8cd9fd;
$blue-200: #b2eafe;
$blue-100: #d8f6fe;

:root {
  --bs-gutter-x: 3.5rem;
  --primary-color: #1d192d;
  --label-primary-color: #e6e6ea;
  --button-primary-color: #7c73f3;
  --placeholder-color: #a3a5c3;
  --link-primary-color: #9976ff;
  --success-color: #{$green-500};
  --error-color: #{$red-500};
  --background-color-primary: #211b36;
  --id-main-purple: #{$purple-500};
  --id-main-black: #111010;
  --id-white: #ededed;
  --id-subtitles: #878787;

  --navigation-width: #{$navigation-width};
  --navigation-width-collapsed: #{$navigation-width-collapsed};
  --navigation-inner-padding: #{$navigation-inner-padding};
  --dashboard-inner-padding: #{$dashboard-inner-padding};
  --table-fixed-footer-height: #{$table-fixed-footer-height};

  --font-extra-light: 100;
  --font-light: 200;
  --font-book: 300;
  --font-regular: 400;
  --font-medium: 475;
  --font--lg-medium: 500;
  --font-semi-bold: 600;
  --font-bold: 700;
  --font-extra-book: 800;

  --neutral-100: #{$neutral-100};
  --neutral-200: #{$neutral-200};
  --neutral-200-50: #{$neutral-200-50};
  --neutral-300: #{$neutral-300};
  --neutral-400: #{$neutral-400};
  --neutral-500: #{$neutral-500};
  --neutral-500-50: #{$neutral-500-50};
  --neutral-600: #{$neutral-600};
  --neutral-600-50: #{$neutral-600-50};
  --neutral-700: #{$neutral-700};
  --neutral-700-70: #{$neutral-700-70};

  --auxiliary-200-24: #{$auxiliary-200-24};

  --black: #{$black};
  --white: #{$white};

  --purple-700: #{$purple-700};
  --purple-600: #{$purple-600};
  --purple-500: #{$purple-500};
  --purple-400: #{$purple-400};
  --purple-300: #{$purple-300};
  --purple-200: #{$purple-200};
  --purple-100: #{$purple-100};

  --red-700: #{$red-700};
  --red-600: #{$red-600};
  --red-500: #{$red-500};
  --red-400: #{$red-400};
  --red-300: #{$red-300};
  --red-200: #{$red-200};
  --red-100: #{$red-100};

  --yellow-700: #{$yellow-700};
  --yellow-600: #{$yellow-600};
  --yellow-500: #{$yellow-500};
  --yellow-400: #{$yellow-400};
  --yellow-300: #{$yellow-300};
  --yellow-200: #{$yellow-200};
  --yellow-100: #{$yellow-100};

  --green-700: #{$green-700};
  --green-600: #{$green-600};
  --green-500: #{$green-500};
  --green-400: #{$green-400};
  --green-300: #{$green-300};
  --green-200: #{$green-200};
  --green-100: #{$green-100};

  --blue-700: #{$blue-700};
  --blue-600: #{$blue-600};
  --blue-500: #{$blue-500};
  --blue-400: #{$blue-400};
  --blue-300: #{$blue-300};
  --blue-200: #{$blue-200};
  --blue-100: #{$blue-100};
}
