.mzic-popover.style-tooltip {
  background-color: var(--neutral-500);
  border-radius: 8px;
  padding: 8px;
  display: inline-flex;
  color: #fff;
  font-size: 10px;
  line-height: 14px;
  max-width: 182px;
  position: absolute;

  > div {
    display: inline-flex;
    width: 100%;
    flex-direction: column;
    gap: 12px;
  }

  &::after {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid var(--neutral-500);
    position: absolute;
    content: '';
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%);
  }

  strong {
    font-size: 10px;
    line-height: 14px;
    font-weight: var(--font-bold);
    color: #fff;
    margin: 0;
    padding: 0;
  }

  p {
    font-size: 10px;
    line-height: 14px;
    font-weight: var(--font-regular);
    color: #fff;
    margin: 0;
    padding: 0;
  }
}
