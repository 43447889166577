.full-page-icon {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(17, 16, 16, 0.8);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;

  .icon {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    border: 4px solid transparent;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    > svg {
      width: 26px;
      height: 26px;
    }

    &.success {
      border-color: var(--green-500);
      > svg > path {
        stroke: var(--green-500);
      }
    }
  }
}
