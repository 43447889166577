.track-status-tag {
  @extend .text-body-xsm-bold;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  border-radius: 8px;
  height: 24px;
  color: var(--neutral-700);
  gap: 6px;

  > span {
    padding-top: 2px;
  }
}
