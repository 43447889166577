@for $i from 320 through 1980 {
  @media (max-width: #{$i}px) {
    .hide-in-#{$i} {
      display: none;
    }
  }
}

@for $i from 0 through 96 {
  .w#{$i} {
    width: #{$i}px;
  }
  .mw#{$i} {
    max-width: #{$i}px;
  }
  .h#{$i} {
    height: #{$i}px;
  }
  .mh#{$i} {
    max-height: #{$i}px;
  }
  .fs#{$i} {
    font-size: #{$i}px;
  }
  .lh#{$i} {
    line-height: #{$i}px;
  }
  .mt#{$i} {
    margin-top: #{$i}px;
  }
  .mb#{$i} {
    margin-bottom: #{$i}px;
  }
  .ml#{$i} {
    margin-left: #{$i}px;
  }
  .mr#{$i} {
    margin-right: #{$i}px;
  }
  .lh#{$i} {
    line-height: #{$i}px;
  }
}
