@use './../../../../mzic-assets/src/lib/fonts/fonts.scss';

@import '@angular/cdk/overlay-prebuilt.css';
@import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';

@import './styles/utilities';
@import './styles/_variables';
@import './styles/main';

html,
body {
  min-height: 100%;
}

/**
 * `width` não é afetado por `padding` ou `border`.
 * já existe essa regra no _reboot.css do bootstrap, mas pretendemos remover o bootstrap
 */
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.material-cdk-dialog-transparent {
  background: transparent;

  .mat-mdc-dialog-container .mdc-dialog__surface {
    background: transparent;
  }
}

.date-releases {
  position: relative;
  background-color: transparent !important;

  ::ng-deep .mat-mdc-button-persistent-ripple mdc-button__ripple {
    color: var(--id-main-purple);
  }
  ::ng-deep .mdc-button__label {
    color: var(--id-main-purple) !important;
  }

  .mat-calendar-table {
    td {
      color: var(--id-main-purple) !important;
    }
    tr {
      color: var(--id-main-purple) !important;
    }
    th {
      color: var(--id-main-purple) !important;
    }
  }

  .mat-calendar-table-header {
    td {
      color: var(--id-main-purple) !important;
    }
    tr {
      color: var(--id-main-purple) !important;
    }
    th {
      color: var(--id-main-purple) !important;
    }
  }

  .mat-focus-indicator {
    color: var(--id-main-purple) !important;
  }

  .mat-calendar-body-label {
    display: none;
  }
  .mat-calendar-body-today {
    width: 24px !important;
    height: 24px !important;
    background-color: transparent !important;
    color: var(--id-white) !important;
    box-shadow: none !important;
  }
  .mat-calendar-body-selected {
    background-color: var(--id-white) !important;
    width: 24px !important;
    height: 24px !important;
    color: var(--id-main-purple) !important;
  }
}

.mat-mdc-menu-panel {
  border-radius: 16px !important;
  margin-top: 10px !important;
  width: 211px !important;
}

.mzic-text-body-xxsm {
  font-size: 14px;
  line-height: 20px;
  color: var(--neutral-200);
}

.mzic-row {
  display: flex;
  width: 100%;
}

// Gaps
@for $i from 1 through 10 {
  .mzic-gap-#{$i} {
    gap: #{$i * 0.25}rem; // 1rem = 16px, 0.25rem = 4px
  }
}

.mzic-page-full-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
}

.mzic-link {
  font-size: 14px;
  line-height: 14px;
  font-weight: var(--font-bold);
  color: var(--purple-500);
}

.circle {
  border-radius: 50%;
}

.tracks-release-button.mz-button.save.mz-button {
  &:disabled,
  &:disabled:hover {
    border-color: var(--purple-600) !important;
    color: var(--purple-600) !important;
    background-color: transparent !important;

    > mzic-svg.neutral-700 > svg > path {
      fill: var(--purple-600) !important;
    }

    > mzic-svg:not(.neutral-700) > svg > path {
      stroke: var(--purple-600) !important;
    }
  }
}

.pac-container {
  margin-top: 4px;
  border-radius: 24px;
  background: var(--neutral-500);
  border: none;
}

.pac-item {
  font-family: 'PP Mori';
  font-size: 14px;
  padding: 8px 16px;
  border: none;

  &:hover {
    background: var(--neutral-600-50);
  }
}

.pac-item .pac-item-query {
  color: var(--neutral-100);
}

.pac-item .pac-item-query + span {
  color: var(--neutral-100);
}
