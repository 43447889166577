.mzic-popover.style-white-small {
  background-color: var(--neutral-100);
  border-radius: 24px;
  padding: 9px 16px;
  gap: 6px;

  > div {
    display: inline-flex;
    padding: 6px 8px 3px;
    font-size: 12px;
    line-height: 12px;
    height: 24px;
    gap: 8px;
    justify-content: flex-start;
    align-items: center;
    font-weight: var(--font-medium);
    width: 100%;
    color: var(--neutral-700);

    > mzic-svg {
      max-width: 14px;
      margin-top: -3px;

      &:not(.stroke) > svg > path {
        fill: var(--neutral-700);
      }
      &.stroke > svg > path {
        stroke: var(--neutral-700);
      }
    }

    a,
    button,
    .clickable,
    &.clickable {
      cursor: pointer;
      display: inline-flex;
      width: 100%;
      border-radius: 100px;

      &:hover {
        background-color: var(--neutral-200-50);
      }
    }
  }
}
