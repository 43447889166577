.sort-number {
  @extend .text-body-xsm-bold;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  padding-top: 3px;
  border-radius: 8px;
  background-color: var(--blue-500);
}
