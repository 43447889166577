.toggle-content-link-component {
  @extend .text-body-md-semibold;
  color: var(--purple-500);
  display: inline-flex;
  align-items: center;
  gap: 8px;

  mzic-svg {
    margin-top: -2px;
  }
}
