.mzic-tabs {
  display: inline-flex;

  > ul {
    display: inline-flex;
    margin: 0;
    gap: 24px;
    padding: 0;

    > li {
      display: inline-flex;
      list-style: none;

      > a {
        position: relative;
        display: inline-flex;
        height: 44px;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: var(--font-regular);
        color: var(--neutral-300) !important;

        &::after {
          position: absolute;
          width: 100%;
          height: 1px;
          background: transparent;
          content: '';
          left: 0;
          bottom: 0;
        }

        &.active {
          color: var(--purple-500) !important;
          font-weight: var(--font-bold) !important;

          &::after {
            background: var(--purple-500);
          }
        }
      }
    }
  }
}
