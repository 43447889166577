.mzic-tip-on-hover {
  position: relative;

  &:hover .mzic-tip {
    display: inline-flex;
  }

  .mzic-tip {
    // display: inline-flex;
    display: none;
    transform: translate(100%, 0);
    position: absolute;
    flex-direction: column;
    padding: 8px;
    gap: 22px;
    border-radius: 8px;
    border: 1px solid var(--neutral-300);
    width: 200px;
    right: -28px;
    top: 0;

    &::before {
      position: absolute;
      width: 32px;
      height: 100%;
      content: '';
      left: -28px;
      top: 0;
    }

    p {
      @extend .text-body-xxsm;
      color: var(--neutral-200);
    }

    strong {
      @extend .text-body-xxsm;
      color: var(--neutral-200);
    }

    .item {
      @extend .text-body-xxsm;
      color: var(--neutral-200);
      align-items: flex-start;

      &.forbidden {
        svg {
          margin-top: -3px;
          margin-right: 4px;
          width: 12px;
          > path {
            fill: var(--red-500);
          }
        }
      }

      mzic-svg {
        display: inline;
      }
    }
  }
}
