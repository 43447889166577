body {
  /* Estilizando a barra de rolagem */
  ::-webkit-scrollbar {
    width: 5px; /* Largura da barra de rolagem */
  }

  /* Estilizando o indicador (alça) da barra de rolagem */
  ::-webkit-scrollbar-thumb {
    background-color: var(--primary-color); /* Cor do indicador */
    border-radius: 5px; /* Raio de borda do indicador */
  }

  /* Estilizando o indicador (alça) da barra de rolagem quando hover */
  ::-webkit-scrollbar-thumb:hover {
    background-color: var(
      --primary-color
    ); /* Cor do indicador ao passar o mouse */
  }

  /* Estilizando a pista (fundo) da barra de rolagem */
  ::-webkit-scrollbar-track {
    background-color: transparent; /* Cor da pista */
  }

  /* Estilizando a pista (fundo) da barra de rolagem quando hover */
  ::-webkit-scrollbar-track:hover {
    background-color: transparent; /* Cor da pista ao passar o mouse */
  }
  .mat-mdc-progress-spinner {
    --mdc-circular-progress-active-indicator-color: var(--id-main-purple);
  }
}

.mzic-scrollbar {
  scrollbar-color: var(--purple-500) var(--neutral-600);
  scrollbar-width: thin;
}
