/////////////////////////////////////////////////////
/// ////// NEUTRAL
/// /////////////////////////////////////////////////
.neutral-100 {
  color: var(--neutral-100) !important;
  &:not(.stroke) > svg > path {
    fill: var(--neutral-100) !important;
  }
  &.stroke > svg > path {
    stroke: var(--neutral-100) !important;
  }
  &.circle > svg > circle {
    stroke: var(--neutral-100) !important;
  }
}
.bg-neutral-100 {
  background-color: var(--neutral-100) !important;
}
.neutral-200 {
  color: var(--neutral-200) !important;
  &:not(.stroke) > svg > path {
    fill: var(--neutral-200) !important;
  }
  &.stroke > svg > path {
    stroke: var(--neutral-200) !important;
  }
  &.circle > svg > circle {
    stroke: var(--neutral-200) !important;
  }
}
.bg-neutral-200 {
  background-color: var(--neutral-200) !important;
}
.neutral-200-50 {
  color: var(--neutral-200-50) !important;
  &:not(.stroke) > svg > path {
    fill: var(--neutral-200-50) !important;
  }
  &.stroke > svg > path {
    stroke: var(--neutral-200-50) !important;
  }
  &.circle > svg > circle {
    stroke: var(--neutral-200-50) !important;
  }
}
.bg-neutral-200-50 {
  background-color: var(--neutral-200-50) !important;
}
.neutral-300 {
  color: var(--neutral-300) !important;
  &:not(.stroke) > svg > path {
    fill: var(--neutral-300) !important;
  }
  &.stroke > svg > path {
    stroke: var(--neutral-300) !important;
  }
  &.circle > svg > circle {
    stroke: var(--neutral-300) !important;
  }
}
.bg-neutral-300 {
  background-color: var(--neutral-300) !important;
}
.neutral-400 {
  color: var(--neutral-400) !important;
  &:not(.stroke) > svg > path {
    fill: var(--neutral-400) !important;
  }
  &.stroke > svg > path {
    stroke: var(--neutral-400) !important;
  }
  &.circle > svg > circle {
    stroke: var(--neutral-400) !important;
  }
}
.bg-neutral-400 {
  background-color: var(--neutral-400) !important;
}
.neutral-500 {
  color: var(--neutral-500) !important;
  &:not(.stroke) > svg > path {
    fill: var(--neutral-500) !important;
  }
  &.stroke > svg > path {
    stroke: var(--neutral-500) !important;
  }
  &.circle > svg > circle {
    stroke: var(--neutral-500) !important;
  }
}
.bg-neutral-500 {
  background-color: var(--neutral-500) !important;
}
.neutral-500-50 {
  color: var(--neutral-500-50) !important;
  &:not(.stroke) > svg > path {
    fill: var(--neutral-500-50) !important;
  }
  &.stroke > svg > path {
    stroke: var(--neutral-500-50) !important;
  }
  &.circle > svg > circle {
    stroke: var(--neutral-500-50) !important;
  }
}
.bg-neutral-500-50 {
  background-color: var(--neutral-500-50) !important;
}
.neutral-600 {
  color: var(--neutral-600) !important;
  &:not(.stroke) > svg > path {
    fill: var(--neutral-600) !important;
  }
  &.stroke > svg > path {
    stroke: var(--neutral-600) !important;
  }
  &.circle > svg > circle {
    stroke: var(--neutral-600) !important;
  }
}
.bg-neutral-600 {
  background-color: var(--neutral-600) !important;
}
.neutral-600-50 {
  color: var(--neutral-600-50) !important;
  &:not(.stroke) > svg > path {
    fill: var(--neutral-600-50) !important;
  }
  &.stroke > svg > path {
    stroke: var(--neutral-600-50) !important;
  }
  &.circle > svg > circle {
    stroke: var(--neutral-600-50) !important;
  }
}
.bg-neutral-600-50 {
  background-color: var(--neutral-600-50) !important;
}
.neutral-700 {
  color: var(--neutral-700) !important;
  &:not(.stroke) > svg > path {
    fill: var(--neutral-700) !important;
  }
  &.stroke > svg > path {
    stroke: var(--neutral-700) !important;
  }
  &.circle > svg > circle {
    stroke: var(--neutral-700) !important;
  }
}
.bg-neutral-700 {
  background-color: var(--neutral-700) !important;
}
.neutral-700-70 {
  color: var(--neutral-700-70) !important;
  &:not(.stroke) > svg > path {
    fill: var(--neutral-700-70) !important;
  }
  &.stroke > svg > path {
    stroke: var(--neutral-700-70) !important;
  }
  &.circle > svg > circle {
    stroke: var(--neutral-700-70) !important;
  }
}
.bg-neutral-700-70 {
  background-color: var(--neutral-700-70) !important;
}

/////////////////////////////////////////////////////
/// ////// BLACK / WHITE
/// /////////////////////////////////////////////////
.black,
button:not(.stroke).black,
button:not(.stroke).black > svg > path,
input[type='button']:not(.stroke).black > svg > path {
  color: var(--black) !important;
  fill: var(--black) !important;
}
.mz-button.black.stroke > svg > path {
  stroke: var(--black) !important;
}
.bg-black {
  background-color: var(--black) !important;
}
.white,
button:not(.stroke).white,
button:not(.stroke).white > svg > path,
input[type='button']:not(.stroke).white > svg > path {
  color: var(--white) !important;
  fill: var(--white) !important;
}
.mz-button.white.stroke > svg > path {
  stroke: var(--white) !important;
}
.bg-white {
  background-color: var(--white) !important;
}

/////////////////////////////////////////////////////
/// ////// PURPLE
/// /////////////////////////////////////////////////
.purple-700 {
  color: var(--purple-700) !important;
  &:not(.stroke) > svg > path {
    fill: var(--purple-700) !important;
  }
  &.stroke > svg > path {
    stroke: var(--purple-700) !important;
  }
  &.circle > svg > circle {
    stroke: var(--purple-700) !important;
  }
}
.bg-purple-700 {
  background-color: var(--purple-700) !important;
}
.purple-600 {
  color: var(--purple-600) !important;
  &:not(.stroke) > svg > path {
    fill: var(--purple-600) !important;
  }
  &.stroke > svg > path {
    stroke: var(--purple-600) !important;
  }
  &.circle > svg > circle {
    stroke: var(--purple-600) !important;
  }
}
.bg-purple-600 {
  background-color: var(--purple-600) !important;
}
.purple-500,
.purple {
  color: var(--purple-500) !important;

  &:not(.stroke) > svg > path {
    fill: var(--purple-500) !important;
  }
  &.stroke > svg > path {
    stroke: var(--purple-500) !important;
  }
  &.circle > svg > circle {
    stroke: var(--purple-500) !important;
  }
}
.bg-purple-500,
.bg-purple {
  background-color: var(--purple-500) !important;
}
.purple-400 {
  color: var(--purple-400) !important;
  &:not(.stroke) > svg > path {
    fill: var(--purple-400) !important;
  }
  &.stroke > svg > path {
    stroke: var(--purple-400) !important;
  }
  &.circle > svg > circle {
    stroke: var(--purple-400) !important;
  }
}
.bg-purple-400 {
  background-color: var(--purple-400) !important;
}
.purple-300 {
  color: var(--purple-300) !important;
  &:not(.stroke) > svg > path {
    fill: var(--purple-300) !important;
  }
  &.stroke > svg > path {
    stroke: var(--purple-300) !important;
  }
  &.circle > svg > circle {
    stroke: var(--purple-300) !important;
  }
}
.bg-purple-300 {
  background-color: var(--purple-300) !important;
}
.purple-200 {
  color: var(--purple-200) !important;
  &:not(.stroke) > svg > path {
    fill: var(--purple-200) !important;
  }
  &.stroke > svg > path {
    stroke: var(--purple-200) !important;
  }
  &.circle > svg > circle {
    stroke: var(--purple-200) !important;
  }
}
.bg-purple-200 {
  background-color: var(--purple-200) !important;
}
.purple-100 {
  color: var(--purple-100) !important;
  &:not(.stroke) > svg > path {
    fill: var(--purple-100) !important;
  }
  &.stroke > svg > path {
    stroke: var(--purple-100) !important;
  }
  &.circle > svg > circle {
    stroke: var(--purple-100) !important;
  }
}
.bg-purple-100 {
  background-color: var(--purple-100) !important;
}

/////////////////////////////////////////////////////
/// ////// RED
/// /////////////////////////////////////////////////
.red-700 {
  color: var(--red-700) !important;
  &:not(.stroke) > svg > path {
    fill: var(--red-700) !important;
  }
  &.stroke > svg > path {
    stroke: var(--red-700) !important;
  }
  &.circle > svg > circle {
    stroke: var(--red-700) !important;
  }
}
.bg-red-700 {
  background-color: var(--red-700) !important;
}
.red-600 {
  color: var(--red-600) !important;
  &:not(.stroke) > svg > path {
    fill: var(--red-600) !important;
  }
  &.stroke > svg > path {
    stroke: var(--red-600) !important;
  }
  &.circle > svg > circle {
    stroke: var(--red-600) !important;
  }
}
.bg-red-600 {
  background-color: var(--red-600) !important;
}
.red-500,
.red {
  color: var(--red-500) !important;
  &:not(.stroke) > svg > path {
    fill: var(--red-500) !important;
  }
  &.stroke > svg > path {
    stroke: var(--red-500) !important;
  }
  &.circle > svg > circle {
    stroke: var(--red-500) !important;
  }
}
.bg-red-500,
.bg-red {
  background-color: var(--red-500) !important;
}
.red-400 {
  color: var(--red-400) !important;
  &:not(.stroke) > svg > path {
    fill: var(--red-400) !important;
  }
  &.stroke > svg > path {
    stroke: var(--red-400) !important;
  }
  &.circle > svg > circle {
    stroke: var(--red-400) !important;
  }
}
.bg-red-400 {
  background-color: var(--red-400) !important;
}
.red-300 {
  color: var(--red-300) !important;
  &:not(.stroke) > svg > path {
    fill: var(--red-300) !important;
  }
  &.stroke > svg > path {
    stroke: var(--red-300) !important;
  }
  &.circle > svg > circle {
    stroke: var(--red-300) !important;
  }
}
.bg-red-300 {
  background-color: var(--red-300) !important;
}
.red-200 {
  color: var(--red-200) !important;
  &:not(.stroke) > svg > path {
    fill: var(--red-200) !important;
  }
  &.stroke > svg > path {
    stroke: var(--red-200) !important;
  }
  &.circle > svg > circle {
    stroke: var(--red-200) !important;
  }
}
.bg-red-200 {
  background-color: var(--red-200) !important;
}
.red-100 {
  color: var(--red-100) !important;
  &:not(.stroke) > svg > path {
    fill: var(--red-100) !important;
  }
  &.stroke > svg > path {
    stroke: var(--red-100) !important;
  }
  &.circle > svg > circle {
    stroke: var(--red-100) !important;
  }
}
.bg-red-100 {
  background-color: var(--red-100) !important;
}

/////////////////////////////////////////////////////
/// ////// YELLOW
/// /////////////////////////////////////////////////
.yellow-700 {
  color: var(--yellow-700) !important;
  &:not(.stroke) > svg > path {
    fill: var(--yellow-700) !important;
  }
  &.stroke > svg > path {
    stroke: var(--yellow-700) !important;
  }
  &.circle > svg > circle {
    stroke: var(--yellow-700) !important;
  }
}
.bg-yellow-700 {
  background-color: var(--yellow-700) !important;
}
.yellow-600 {
  color: var(--yellow-600) !important;
  &:not(.stroke) > svg > path {
    fill: var(--yellow-600) !important;
  }
  &.stroke > svg > path {
    stroke: var(--yellow-600) !important;
  }
  &.circle > svg > circle {
    stroke: var(--yellow-600) !important;
  }
}
.bg-yellow-600 {
  background-color: var(--yellow-600) !important;
}
.yellow-500,
.yellow {
  color: var(--yellow-500) !important;
  &:not(.stroke) > svg > path {
    fill: var(--yellow-500) !important;
  }
  &.stroke > svg > path {
    stroke: var(--yellow-500) !important;
  }
  &.circle > svg > circle {
    stroke: var(--yellow-500) !important;
  }
}
.bg-yellow-500,
.bg-yellow {
  background-color: var(--yellow-500) !important;
}
.yellow-400 {
  color: var(--yellow-400) !important;
  &:not(.stroke) > svg > path {
    fill: var(--yellow-400) !important;
  }
  &.stroke > svg > path {
    stroke: var(--yellow-400) !important;
  }
  &.circle > svg > circle {
    stroke: var(--yellow-400) !important;
  }
}
.bg-yellow-400 {
  background-color: var(--yellow-400) !important;
}
.yellow-300 {
  color: var(--yellow-300) !important;
  &:not(.stroke) > svg > path {
    fill: var(--yellow-300) !important;
  }
  &.stroke > svg > path {
    stroke: var(--yellow-300) !important;
  }
  &.circle > svg > circle {
    stroke: var(--yellow-300) !important;
  }
}
.bg-yellow-300 {
  background-color: var(--yellow-300) !important;
}
.yellow-200 {
  color: var(--yellow-200) !important;
  &:not(.stroke) > svg > path {
    fill: var(--yellow-200) !important;
  }
  &.stroke > svg > path {
    stroke: var(--yellow-200) !important;
  }
  &.circle > svg > circle {
    stroke: var(--yellow-200) !important;
  }
}
.bg-yellow-200 {
  background-color: var(--yellow-200) !important;
}
.yellow-100 {
  color: var(--yellow-100) !important;
  &:not(.stroke) > svg > path {
    fill: var(--yellow-100) !important;
  }
  &.stroke > svg > path {
    stroke: var(--yellow-100) !important;
  }
  &.circle > svg > circle {
    stroke: var(--yellow-100) !important;
  }
}
.bg-yellow-100 {
  background-color: var(--yellow-100) !important;
}

/////////////////////////////////////////////////////
/// ////// GREEN
/// /////////////////////////////////////////////////
.green-700 {
  color: var(--green-700) !important;
  &:not(.stroke) > svg > path {
    fill: var(--green-700) !important;
  }
  &.stroke > svg > path {
    stroke: var(--green-700) !important;
  }
  &.circle > svg > circle {
    stroke: var(--green-700) !important;
  }
}
.bg-green-700 {
  background-color: var(--green-700) !important;
}
.green-600 {
  color: var(--green-600) !important;
  &:not(.stroke) > svg > path {
    fill: var(--green-600) !important;
  }
  &.stroke > svg > path {
    stroke: var(--green-600) !important;
  }
  &.circle > svg > circle {
    stroke: var(--green-600) !important;
  }
}
.bg-green-600 {
  background-color: var(--green-600) !important;
}
.green-500,
.green {
  color: var(--green-500) !important;
  &:not(.stroke) > svg > path {
    fill: var(--green-500) !important;
  }
  &.stroke > svg > path {
    stroke: var(--green-500) !important;
  }
  &.circle > svg > circle {
    stroke: var(--green-500) !important;
  }
}
.bg-green-500,
.bg-green {
  background-color: var(--green-500) !important;
}
.green-400 {
  color: var(--green-400) !important;
  &:not(.stroke) > svg > path {
    fill: var(--green-400) !important;
  }
  &.stroke > svg > path {
    stroke: var(--green-400) !important;
  }
  &.circle > svg > circle {
    stroke: var(--green-400) !important;
  }
}
.bg-green-400 {
  background-color: var(--green-400) !important;
}
.green-300 {
  color: var(--green-300) !important;
  &:not(.stroke) > svg > path {
    fill: var(--green-300) !important;
  }
  &.stroke > svg > path {
    stroke: var(--green-300) !important;
  }
  &.circle > svg > circle {
    stroke: var(--green-300) !important;
  }
}
.bg-green-300 {
  background-color: var(--green-300) !important;
}
.green-200 {
  color: var(--green-200) !important;
  &:not(.stroke) > svg > path {
    fill: var(--green-200) !important;
  }
  &.stroke > svg > path {
    stroke: var(--green-200) !important;
  }
  &.circle > svg > circle {
    stroke: var(--green-200) !important;
  }
}
.bg-green-200 {
  background-color: var(--green-200) !important;
}
.green-100 {
  color: var(--green-100) !important;
  &:not(.stroke) > svg > path {
    fill: var(--green-100) !important;
  }
  &.stroke > svg > path {
    stroke: var(--green-100) !important;
  }
  &.circle > svg > circle {
    stroke: var(--green-100) !important;
  }
}
.bg-green-100 {
  background-color: var(--green-100) !important;
}

/////////////////////////////////////////////////////
/// ////// BLUE
/// /////////////////////////////////////////////////
.blue-700 {
  color: var(--blue-700) !important;
  &:not(.stroke) > svg > path {
    fill: var(--blue-700) !important;
  }
  &.stroke > svg > path {
    stroke: var(--blue-700) !important;
  }
  &.circle > svg > circle {
    stroke: var(--blue-700) !important;
  }
}
.bg-blue-700 {
  background-color: var(--blue-700) !important;
}
.blue-600 {
  color: var(--blue-600) !important;
  &:not(.stroke) > svg > path {
    fill: var(--blue-600) !important;
  }
  &.stroke > svg > path {
    stroke: var(--blue-600) !important;
  }
  &.circle > svg > circle {
    stroke: var(--blue-600) !important;
  }
}
.bg-blue-600 {
  background-color: var(--blue-600) !important;
}
.blue-500,
.blue {
  color: var(--blue-500) !important;
  &:not(.stroke) > svg > path {
    fill: var(--blue-500) !important;
  }
  &.stroke > svg > path {
    stroke: var(--blue-500) !important;
  }
  &.circle > svg > circle {
    stroke: var(--blue-500) !important;
  }
}
.bg-blue-500,
.bg-blue {
  background-color: var(--blue-500) !important;
}
.blue-400 {
  color: var(--blue-400) !important;
  &:not(.stroke) > svg > path {
    fill: var(--blue-400) !important;
  }
  &.stroke > svg > path {
    stroke: var(--blue-400) !important;
  }
  &.circle > svg > circle {
    stroke: var(--blue-400) !important;
  }
}
.bg-blue-400 {
  background-color: var(--blue-400) !important;
}
.blue-300 {
  color: var(--blue-300) !important;
  &:not(.stroke) > svg > path {
    fill: var(--blue-300) !important;
  }
  &.stroke > svg > path {
    stroke: var(--blue-300) !important;
  }
  &.circle > svg > circle {
    stroke: var(--blue-300) !important;
  }
}
.bg-blue-300 {
  background-color: var(--blue-300) !important;
}
.blue-200 {
  color: var(--blue-200) !important;
  &:not(.stroke) > svg > path {
    fill: var(--blue-200) !important;
  }
  &.stroke > svg > path {
    stroke: var(--blue-200) !important;
  }
  &.circle > svg > circle {
    stroke: var(--blue-200) !important;
  }
}
.bg-blue-200 {
  background-color: var(--blue-200) !important;
}
.blue-100 {
  color: var(--blue-100) !important;
  &:not(.stroke) > svg > path {
    fill: var(--blue-100) !important;
  }
  &.stroke > svg > path {
    stroke: var(--blue-100) !important;
  }
  &.circle > svg > circle {
    stroke: var(--blue-100) !important;
  }
}
.bg-blue-100 {
  background-color: var(--blue-100) !important;
}
