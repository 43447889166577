.mzic-popover-outside {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  cursor: default;

  &.show-background-true {
    background-color: rgba(17, 16, 16, 0.8);
  }
}

.mzic-popover {
  position: absolute;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 110;
  max-width: none;
  width: fit-content;
  overflow: visible;
  opacity: 0;

  &.fixed-true {
    position: fixed;
  }

  &.active {
    opacity: 1;
  }
}

.mzic-dialog {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 16px;
  background-color: var(--neutral-600);
  padding: 16px 24px 24px 24px;
  width: 350px;
  gap: 0;

  &.mzic-dialog--overlay {
    .mat-mdc-dialog-container {
      overflow-y: visible !important;
    }
  }

  .mat-mdc-dialog-container {
    max-height: 86vh;
    overflow-y: hidden;
  }

  .mat-mdc-dialog-container .mdc-dialog__surface {
    background: transparent;
    box-shadow: none;
    overflow: visible;
  }

  .header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 0;

    &.flex-end {
      justify-content: flex-end;
    }

    &.no-margin {
      margin: 0 !important;
    }

    .title {
      @extend .text-body-md-semibold;
      display: inline-flex;
      padding-right: 27px;
      align-items: center;
      gap: 8px;
    }

    .mz-button.close {
      margin-top: 4px;
      padding: 0;
      height: 16px;
      width: 16px;
    }
  }

  .footer-sticky {
    position: sticky;
    bottom: 0px;
    background: var(--neutral-600);
    z-index: 10;
    padding: 12px 0 !important;

    .footer {
      margin-top: inherit;
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    margin-top: 28px;
    padding: 0;
    width: 100%;

    &.flex-end {
      justify-content: flex-end;
    }

    button:not(.full) {
      width: auto;
    }

    &.padding {
      padding-left: 24px;
      padding-right: 24px;
    }
  }
}
