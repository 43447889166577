.mzic-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;

  &.min-height {
    min-height: 50vh;
  }

  &.bordered tbody tr {
    border-bottom: 1px solid var(--neutral-500);
  }

  table {
    color: #fff;
    width: 100%;
    height: fit-content;

    thead {
      border-bottom: 1px solid var(--neutral-500);
    }

    thead,
    tbody {
      width: 100%;
    }

    thead th {
      padding: 12px 12px 10px;
    }

    thead th > div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 4px;

      mzic-table-sort {
        margin-left: 4px;
      }
    }

    thead th label {
      height: 18px;
      font-size: 14px;
      color: var(--neutral-300);
    }

    tbody td {
      padding: 20px 12px 18px;
      font-size: 14px;
      height: 94px;
    }

    tbody tr {
      transition: 0.3s;
      position: relative;

      &::after {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        content: '';
        border: 2px solid transparent;
        border-radius: 16px;
        z-index: -1;
      }

      &.sortable-chosen:hover {
        &::after {
          border-color: var(--purple-500);
          background-color: var(--neutral-500);
        }
      }

      &.opened {
        background: var(--neutral-500);
      }

      &.hoverable:hover {
        background: var(--neutral-500);
      }
      &.checked {
        background: var(--neutral-500-50);
      }
    }

    tbody.empty {
      td > div {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 16px;
      }
    }

    .mzic-table-column-date-time {
      width: 120px;
      .date {
        display: block;
        font-size: 14px;
        line-height: 21px;
        color: var(--neutral-100);
        font-weight: var(--font-medium);
      }
      .time {
        display: block;
        font-size: 12px;
        line-height: 18px;
        color: var(--neutral-300);
        font-weight: var(--font-medium);
      }
    }

    .mzic-table-column-transaction-type {
      .icon {
        display: inline-block;
        width: 34px;
        height: 34px;
        line-height: 34px;
        border-radius: 100%;
        background-color: var(--neutral-200);
        text-align: center;
        vertical-align: middle;
        margin-right: 8px;

        > mzic-svg {
          display: inline;
        }
      }
    }

    .mzic-table-column-release-date {
      min-width: 156px;
    }

    .mzic-table-column-w30 {
      width: 30px;
      text-align: center;
      padding-left: 4px;
      padding-right: 4px;
    }

    .mzic-table-column-w20 {
      width: 20px;
      text-align: center;
      padding-left: 4px;
      padding-right: 4px;
      > *:not(.sort-number) {
        display: inline;
      }
    }

    .mzic-table-column-w40 {
      width: 40px;
      text-align: center;
      padding-left: 4px;
      padding-right: 4px;
      > *:not(.sort-number) {
        display: inline;
      }
    }

    .mzic-table-column-small {
      width: 30px;
      vertical-align: middle;

      > *:not(mzic-skeleton) {
        display: inline;
      }
    }

    .mzic-table-column-dsp-service img {
      margin-right: 3px;
    }

    .mzic-table-match-check {
      width: 62px;
      text-align: center;
    }

    .mzic-table-main-genre-check {
      min-width: 130px;
    }

    .mzic-table-column-check {
      width: 20px;
      padding-right: 0;
      text-align: center;
      vertical-align: middle;

      .mzic-checkbox,
      .mzic-checkbox .fake-checkbox {
        display: block;
      }
    }

    .mzic-table-column-actions-button {
      width: 50px;
      text-align: center;
      justify-content: center;
      display: inline-flex;
      height: 100%;
    }

    .mzic-table-column-dsp-status {
      width: 43px;
      text-align: center;
      padding-left: 0;
      padding-right: 0;

      > * {
        display: inline;
      }

      > mzic-svg,
      > mzic-svg > svg {
        width: 18px;
        max-height: 18px;
      }
    }

    .mzic-table-column-bullets {
      width: 22px;
      text-align: center;
      padding-left: 0;
      padding-right: 0;
    }

    .mzic-table-column-sort {
      text-align: center;
      width: 24px;
      padding-left: 0;
      padding-right: 0;

      > mzic-svg {
        display: inline;
        margin-top: -2px;
      }
    }
    tbody .mzic-table-column-sort {
      cursor: move;
    }

    .mzic-column-date-time {
      width: 150px;

      > div {
        display: inline-flex;
        flex-direction: column;
        gap: 2px;

        span {
          font-size: 14px;
          line-height: 21px;
          color: var(--neutral-100);

          &.time {
            color: var(--neutral-300);
          }
        }
      }
    }

    .mzic-table-column-play {
      padding-left: 0;
      padding-right: 0;
      text-align: center;
      width: 32px;

      > mzic-svg {
        display: inline;
      }
    }

    /// debug
    // tr :is(td, th):nth-child(1) {
    //   background: red;
    // }
    // tr :is(td, th):nth-child(2) {
    //   background: blue;
    // }
    // tr :is(td, th):nth-child(3) {
    //   background: green;
    // }
    // tr :is(td, th):nth-child(4) {
    //   background: pink;
    // }
    // tr :is(td, th):nth-child(5) {
    //   background: purple;
    // }
    // tr :is(td, th):nth-child(6) {
    //   background: yellow;
    // }
    // tr :is(td, th):nth-child(7) {
    //   background: brown;
    // }
  }

  .empty-container {
    padding-top: 26px;
    padding-bottom: 26px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  footer {
    display: flex;
    width: 100%;
    border-top: 1px solid var(--neutral-500);
    justify-content: space-between;
    padding: 10px 12px;
    color: #fff;

    > div {
      display: inline-flex;
      align-items: center;
      gap: 8px;
    }

    .items-total {
      font-size: 14px;
      color: var(--neutral-300);

      span {
        color: #fff;
      }
    }
  }
}

.mzic-table.medium {
  tbody td {
    padding-top: 2px;
    padding-bottom: 0;
    height: 76px;
  }
}

.mzic-table.small {
  tbody td {
    padding-top: 0;
    padding-bottom: 0;
    height: 48px;
  }
}

.mzic-table.fixed-footer {
  margin-bottom: var(--table-fixed-footer-height);
}

.mzic-table.fixed-footer footer {
  position: fixed;
  width: calc(100% - #{$navigation-width} - (2 * #{$dashboard-inner-padding}));
  z-index: 1;
  padding-top: 8px;
  left: calc(#{$navigation-width} + #{$dashboard-inner-padding});
  bottom: 0;
  height: var(--table-fixed-footer-height);
  align-items: flex-start;
  background-color: var(--id-main-black);
  transition:
    width 0.3s,
    left 0.3s;
}

body.navigation-collapsed .mzic-table.fixed-footer footer {
  width: calc(
    100% - #{$navigation-width-collapsed} - (2 * #{$dashboard-inner-padding})
  );
  left: calc(#{$navigation-width-collapsed} + #{$dashboard-inner-padding});
}
