.mzic-checkbox {
  position: relative;
  display: inline-flex;

  input[type='checkbox'] {
    width: 16px;
    height: 16px;
    position: absolute;
    z-index: 2;
    opacity: 0;
    left: 0;
    top: 0;
    cursor: pointer;

    &.error + .fake-checkbox {
      border-color: var(--red-500);
    }
  }

  .fake-checkbox {
    position: relative;
    display: inline-flex;
    width: 16px;
    height: 16px;
    border: 1px solid var(--neutral-300);
    border-radius: 6px;
    background-color: transparent;
    cursor: pointer;
    overflow: hidden;
    background-size: 13px;
    background-position: center center;
    background-repeat: no-repeat;

    &.checked {
      background-image: url('/assets/icons/mzic-check-mark-white.svg');
      background-color: var(--purple-500);
      border-color: var(--purple-500);
    }
  }
}
