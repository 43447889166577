$padding-left: 14px;
$padding-right: 6px;

.split-details-payees-list-component {
  display: inline-flex;
  width: 100%;
  flex-direction: column;
  gap: 8px;

  .split-row {
    display: flex;
    width: 100%;
    justify-content: space-between;

    &.row-head {
      padding-left: $padding-left;
      padding-right: $padding-right;

      .split-column {
        color: var(--neutral-300);
        font-weight: var(--font-bold);
        font-size: 14px;
        line-height: 19px;
      }
    }

    &:not(.row-head) {
      padding: 16px $padding-right 16px $padding-left;
      border: 1px solid #888;
      border-radius: 24px;
      border: 1px solid var(--neutral-500);

      &.flat {
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-radius: 0;
      }
    }
  }

  .split-column {
    display: inline-flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    color: #fff;
    width: 100%;
  }

  .col-name {
    display: inline-flex;
    gap: 12px;

    .col-name-content {
      display: inline-flex;
      flex-direction: column;
      justify-content: center;

      .name {
        color: var(--neutral-100);
        font-weight: var(--font-semi-bold);
        font-size: 14px;
        line-height: 21px;
      }

      .email {
        font-size: 12px;
        line-height: 18px;
        color: var(--neutral-300);
      }
    }

    > .image {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .col-tag {
    justify-content: center;
    max-width: 200px;

    .tag {
      @extend .text-body-sm-bold;
      padding: 3px 10px 1px;
      background-color: var(--blue-500);
      color: var(--neutral-700);
      border-radius: 4px;
      font-size: 10px;
      line-height: 14px;
      text-transform: uppercase;
    }
  }

  .col-percent {
    @extend .text-body-xxsm;
    justify-content: center;
    color: var(--neutral-100);
    max-width: 100px;
  }

  .col-icon {
    max-width: 32px;
  }

  .col-money {
    justify-content: flex-start;
    max-width: 84px;
  }

  .col-icon-msg {
    display: inline-flex;
    max-width: 200px;
    justify-content: center;

    > div {
      display: flex;
      gap: 8px;
      align-items: center;
      cursor: pointer;
      padding: 0 4px;

      &:hover {
        mzic-svg {
          @extend .neutral-200;
        }

        label {
          color: var(--neutral-200);
        }
      }

      mzic-svg {
        @extend .neutral-300;
      }

      label {
        @extend .text-body-xxsm-semibold;
        color: var(--neutral-300);
        margin-bottom: -2px;
        cursor: pointer;
      }
    }
  }

  .col-status {
    justify-content: center;
    max-width: 150px;

    .status {
      @extend .text-body-sm-bold;
      text-transform: uppercase;
      padding: 3px 10px 1px;
      border-radius: 4px;
      font-size: 10px;
      line-height: 14px;
      background-color: var(--neutral-600);

      &.confirmed {
        color: var(--green-500);
      }
      &.action-required {
        background-color: var(--yellow-500);
        color: var(--neutral-600);
      }
      &.pending {
        color: var(--purple-500);
      }
      &.suspended {
        color: var(--red-500);
      }
    }
  }

  .split-row:nth-child(1),
  .split-row:nth-child(5),
  .split-row:nth-child(10),
  .split-row:nth-child(15) {
    .split-column.col-name .image {
      background: linear-gradient(45deg, #16b0e2 8.55%, #6e5af0 91.45%);
    }
  }

  .split-row:nth-child(2),
  .split-row:nth-child(6),
  .split-row:nth-child(11),
  .split-row:nth-child(16) {
    .split-column.col-name .image {
      background: linear-gradient(45deg, #e21616 8.55%, #f0a25a 91.45%);
    }
  }

  .split-row:nth-child(3),
  .split-row:nth-child(7),
  .split-row:nth-child(12),
  .split-row:nth-child(17) {
    .split-column.col-name .image {
      background: linear-gradient(45deg, #e21616 8.55%, #6e5af0 91.45%);
    }
  }

  .split-row:nth-child(4),
  .split-row:nth-child(8),
  .split-row:nth-child(13),
  .split-row:nth-child(18) {
    .split-column.col-name .image {
      background: linear-gradient(45deg, #00bf8f 8.55%, #002c22 91.45%);
    }
  }
}
