.common-style {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

#mzicToastContainer {
  position: fixed;
  bottom: 24px;
  right: 24px;
  z-index: 101;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: hidden;
}

@keyframes toast-enter-animation {
  from {
    opacity: 0;
    right: -400px;
  }
  to {
    opacity: 1;
    right: 0;
  }
}

.mzic-popover.style-toast {
  background-color: var(--neutral-100);
  padding: 24px 24px 20px;
  border-radius: 16px;
  min-width: 375px;
  max-width: 935px;
  width: fit-content;
  overflow: hidden;
  @extend .common-style;
  position: relative;
  opacity: 1;
  animation-name: toast-enter-animation;
  animation-duration: 0.3s;

  @media (max-width: 430px) {
    max-width: 98vw;
  }

  mzic-svg {
    margin-top: -3px;
  }

  > ng-component {
    @extend .common-style;
    width: 100%;
  }

  &.medium {
    padding: 19px 24px 16px;
  }

  > div,
  > ng-component > div {
    display: inline-flex;
    align-items: center;
    gap: 13px;
  }

  .message-container {
    display: inline-flex;
    flex-direction: column;
    gap: 8px;

    .main {
      font-size: 16px;
      line-height: 18px;
      color: var(--neutral-700);
      font-weight: var(--font-bold);
    }

    .secondary {
      font-size: 16px;
      line-height: 18px;
      color: var(--neutral-700);
      font-weight: var(--font-regular);
    }
  }

  .link-btn {
    color: var(--purple-500);
    font-size: 14px;
    line-height: 20px;
    font-weight: var(--font-semi-bold);
    padding: 2px 24px;
  }

  .progress {
    position: absolute;
    height: 4px;
    background-color: var(--purple-500);
    bottom: 0;
    left: 0;
  }
}
