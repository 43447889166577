.mzic-alert-container {
  display: inline-flex;
  flex-direction: column;
  gap: 8px;

  &.no-gap {
    gap: 0;
  }
}

.mzic-alert {
  display: inline-flex;
  align-items: center;
  gap: 13px !important;
  padding: 10px 24px 7px !important;
  border: 1px solid transparent;
  border-radius: 16px;

  > mzic-svg {
    width: 21px;
    max-height: 21px;
    margin-top: -5px;
  }

  > p {
    @extend .text-body-xsm;
    margin: 0 !important;
    font-size: 12px !important;
    line-height: 16px !important;
  }

  &.error {
    border-color: var(--red-500);

    p {
      color: var(--red-300) !important;
    }

    > mzic-svg > svg > path {
      fill: var(--red-500);
    }
  }

  &.error.filled {
    background-color: var(--red-500);

    p {
      color: var(--neutral-700) !important;
    }

    > mzic-svg > svg > path {
      fill: var(--neutral-700);
    }
  }

  &.warning {
    border-color: var(--yellow-500);

    p {
      color: var(--yellow-500) !important;
    }

    > mzic-svg > svg > path {
      fill: var(--yellow-500);
    }
  }

  &.warning.filled {
    background-color: var(--yellow-500);

    p {
      color: var(--neutral-700) !important;
    }

    > mzic-svg > svg > path {
      fill: var(--neutral-700);
    }
  }
}
